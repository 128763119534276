import React, { useState } from "react";
import Layout from "../components/Layout";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";
import Banner from "../images/Contact/banner.jpg";
import { GoMail } from "react-icons/go";
import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import emailjs from "emailjs-com";
import "firebase/auth";
import CheckedIcon from "../images/Contact/checked.png";
import { useForm } from "react-hook-form";
import { CountryDropdown } from "react-country-region-selector";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import InputPhone from "react-phone-number-input/input";

function ContactUs() {
  const [countries, setCountries] = useState("");
  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState(false)
  const [countryError, setCountryError] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError
  } = useForm();

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  if (modal) {
    setTimeout(() => {
      setModal(false);
    }, 2000);
  }

  const submitButtonClicked = (data, e) => {
    e.preventDefault();

    if(phone && countries){
    console.log("Data: " + JSON.stringify(data) + " phone " + phone);

    console.log("Selected COuntry: " + countries);

    setPhoneError(false)
    setCountryError(false)
    

    var template_params = {
      fullName: data.fullName,
      userEmail: data.email1,
      phone: phone,
      enquiry: data.enquiry,
      message: data.message,
    };

    setModal(true);

    emailjs
      .send(
        "service_1qnspxs",
        "template_im8dvdb",
        template_params,
        "user_C9512qbCLCTy86RLPgN2z"
      )
      .then(
        (result) => {
          console.log(result.text);
          setPhone("");
          e.target.reset();
          reset();

          setPhone()

          
          // alert("Your contact query was sent.");
        },
        (error) => {
          console.log(error.text);
        }
      );
    }else{
      if(!phone){
        setPhoneError(true)
      }
      if(!countries){
        setCountryError(true)
      }
    }
  };
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const Popup = () => {
    return (
      <Modal isOpen={modal} toggle={toggle} className="contact_modal">
        {/* <ModalHeader>Modal title</ModalHeader> */}
        <ModalBody className="contact_modal_body">
          <img src={CheckedIcon} alt="The mail was sent" />
          <h2>Thank you!</h2>
          <p>Your submission hes been sent.</p>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>
            OK
          </Button>{" "}
        </ModalFooter> */}
      </Modal>
    );
  };

  console.log("Country: " + countries);
  return (
    <Layout>
      <section className="story_banner">
        {modal && Popup()}
        <Container>
          <Row>
            <Col md="12">
              <img src={Banner} alt="img" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact_section">
        <Container>
          <Row>
            <Col md="6">
              <h1>Get In Touch</h1>
              <hr />
              <div className="contact_card">
                <a href="mailto:info@greyspacefurniture.com">
                  <div className="contact_icon">
                    <GoMail size={25} />
                  </div>
                  <p>info@greyspacefurniture.com</p>
                </a>
              </div>
              <div className="contact_card">
                <a href="tel:+919680336336">
                  <div className="contact_icon">
                    <FaPhoneAlt size={25} />
                  </div>
                  <p>+91 968 033 6336</p>
                </a>
              </div>
              <div className="contact_card">
                <a href="#">
                  <div className="contact_icon">
                    <FaMapMarkerAlt size={25} />
                  </div>
                  <p>Jaipur, Rajasthan - 303704, INDIA</p>
                </a>
              </div>
            </Col>
            <Col md="6">
              <h1>Submit Your Query Here!</h1>
              <hr />
              <Form
                className="contact_form"
                onSubmit={handleSubmit(submitButtonClicked)}
              >
                <FormGroup>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    {...register("fullName", { required: true })}
                  />

                  {errors.fullName && errors.fullName.type === "required" && (
                    <p className="errorMsg">Fullname is required.</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email ID"
                    {...register("email1", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                  />

                  {errors.email1 && errors.email1.type === "required" && (
                    <p className="errorMsg">Email is required.</p>
                  )}
                  {errors.email1 && errors.email1.type === "pattern" && (
                    <p className="errorMsg">
                      Please enter a valid email address.
                    </p>
                  )}
                </FormGroup>
                <FormGroup row>
                  <Col sm={6}>
                    <CountryDropdown
                      value={countries}
                      valueType="short"
                      onChange={(val) => setCountries(val)}
                    />
                    {countryError && <p>Please select a country</p>}
                  </Col>
                  <Col sm={6}>
                    <InputPhone
                      className="inpout_phone"
                      country={countries}
                      international
                      withCountryCallingCode={true}
                      name="contact"
                      placeholder="Contact No"
                      onChange={setPhone}
                    
                    />
                    {phoneError && <p>Phone number is required</p>}
                    </Col>
               
                </FormGroup>
                <FormGroup>
                  <select
                    type="select"
                    name="enquiry"
                    {...register("enquiry", { required: true })}
                  >
                    <option value="Enquiry">Enquiry</option>
                    <option value="End User">End User</option>
                    <option value="Reseller">Reseller</option>
                  </select>

                  {errors.enquiry && errors.enquiry.type === "required" && (
                    <p className="errorMsg">Enquiry is required.</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <textarea
              
                    name="message"
                    placeholder="Your message"
                    {...register("message", { required: true })}
                  />

                  {errors.message && errors.message.type === "required" && (
                    <p className="errorMsg">Please enter a message.</p>
                  )}
                </FormGroup>
                <Button type="submit">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default ContactUs;
